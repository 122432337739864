import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './upgrade.css'
import getProducts from 'api/get-products'
import Product from 'types/product'
import formatNum from 'utils/format-num'
import AppContext from 'utils/app-context'
import { AnimatePresence, motion } from 'framer-motion'
import UpgradeModal from './components/UpgradeModal'
import Img from 'UI/Img'
import UpdateTimer from './components/UpdateTimer'
import Icon from 'UI/Icon'
import { useSearchParams } from 'react-router-dom'
type Props = {}

const Upgrade = (props: Props) => {
    const [searchParams] = useSearchParams()
    const [produсts, setproduсts] = useState<Product[] | null>(null)
    const initialCategory = Number(searchParams.get('category'))
    const [category, setCategory] = useState<number>(initialCategory || 1)
    const [selectedProduct, setselectedProduct] = useState<Product | null>(null)
    const {user,t} = useContext(AppContext)
    const text = t('upgradePage')
    const updateProducts = async () => {
        const result = await getProducts()
        if (result) setproduсts(result)
    }
    
    useEffect(() => {
        updateProducts()
    }, [])
    return (
    <PageWrapper > 
        <div className="upgrade-screen" style={{overflow: selectedProduct ? 'hidden' : 'auto'}}>
    <div className="upgrade-header">
        <h2 className="upgrade-title">{text.pageTitle}</h2>
        <div className="coin-count">
            <img src="/img/coin4.png" alt=""/>
            <div className="coin-count-textcoin-count-text">{formatNum(user?.balance || 0,true)}</div>
        </div>
    </div>
    <div className="upgrade-types">
        <div className={`standart ${category !== 1 && 'type-desactive'}`} onClick={() => setCategory(1)}>{text.itemTypeStandart}</div>
        <div className={`vip ${category !== 2 && 'type-desactive'}`} onClick={() => setCategory(2)}>{text.itemTypeVIP}</div>
        <div className={`vip inventory ${category !== 3 && 'type-desactive'}`} onClick={() => setCategory(3)}>{text.itemTypeInventory}</div>
    </div>
    <div className="standart-items-container">
    <AnimatePresence mode='wait'>
            <motion.div
                key={category}
                initial={{opacity:0,translateX:50}}
                animate={{opacity:1,translateX:0}}
                transition={{duration:0.1}}
                exit={{opacity:0,translateX:-50}}
                className="standart-items"
            >
                {produсts?.filter(({category:Pcat}) => {
                if(Pcat !== category) return false
                     return true
                }).length === 0 && <p className='empty-upgrades'>{category === 3 ? 'You don\'t have any upgrades' : 'There are no more upgrades'}</p>}
                {produсts ? produсts.map((product) => {
                    const {price_stars,price_ton,price_coins,title,image,category:Pcat,id,active} = product
                    if(Pcat !== category) return false
                    const inInventory = category === 3
                    return (
                        <div key={id} className="standart-item open-standart-item standart-ex-item" onClick={() => setselectedProduct(product)} data-isvip={!Boolean(product.price_coins)}>
                        <div className="standart-item-icon">
                            <Img src={`/img/avatars/${image}`} size={66}/>
                            <UpdateTimer initialSeconds={product.seconds} onEnd={updateProducts}/>
                        </div>
                        <div className="standart-item-title">{title}</div>
                            {
                                !!price_coins && !inInventory && (
                                    <div className="standart-item-cost" >
                                    <div className="standart-image"></div>
                                    <div className="coin-count-text">{formatNum(price_coins)}</div>
                                </div>
                                )
                                    
                            }
                            {!!price_stars && !inInventory && <div className="star">
                                <Img src="/img/star.svg" size={25}/>
                                <div className="star-text">{formatNum(price_stars)}</div>
                            </div>}
                            {!!price_ton && !inInventory &&<div className="rubin">
                                <Img src="/img/rubin.svg" size={25}/>
                                <div className="rubin-text">{price_ton}</div>
                            </div>}
                            {inInventory && (
                               active ? (
                                    <p className='item-status item-status--active'>Active</p>
                                ) : (
                                   <p className='item-status'>Disabled</p>
                               )
                            )}
                        </div>
                    )}) : <div style={{display:'flex',justifyContent:'center',width:'100%'}}><Icon icon='loading'/></div>}
       
            </motion.div>
    </AnimatePresence>
    </div>
    </div>
    <UpgradeModal product={selectedProduct} setProduct={setselectedProduct} refreshList={updateProducts}/>


</PageWrapper>
  )
}

export default Upgrade