import { AnimatePresence, motion } from "framer-motion";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import Alert from "types/alert";
import Task from "types/task";
import Icon from "UI/Icon";
import AppContext from "utils/app-context";
import tg from "utils/tg";

type Props = {
  shareMenu: boolean;
  setShareMenu: (v: boolean) => void;
  task: Task;
};

const tg_id = tg.initDataUnsafe.user?.id;

const VideoTaskShare = ({ setShareMenu, shareMenu, task }: Props) => {
  const [copy, setcopy] = useState(false);
  const {t} = useContext(AppContext)
  const text = t('videoTasks')
  const shareLink =
    process.env.REACT_APP_SHARE_LINK?.replace("TG_ID", String(tg_id)) +
    `_${task.id}`;
  const shareText = `**${task.title}**\n__Watch the short video and express your opinion!__`;

  const TelegramShare = async () => {
    tg.openTelegramLink(
      `https://t.me/share/url?url=${shareLink}&text=${encodeURIComponent(
        shareText
      )}`
    );
  };

  const copyShareLink = (e: React.MouseEvent) => {
    navigator.clipboard.writeText(shareLink);
    setcopy(true);
  };

  // const WhatsAppShare = () => {
  //   tg.openLink(`whatsapp://send?text=${shareText}\n${shareLink}`);
  // };
  // const ViberShare = () => {
  //   tg.openLink(`viber://forward?text=${shareText}\n${shareLink}`);
  // };
  // const XShare = () => {
  //   tg.openLink(
  //     `https://twitter.com/intent/tweet?text=${encodeURIComponent(
  //       shareText
  //     )}&url=${encodeURIComponent(shareLink)}`
  //   );
  // };

  // const EmailShare = () => {
  //   tg.openLink(
  //     `mailto:?subject=${encodeURIComponent(
  //       task.title
  //     )}&body=${encodeURIComponent(shareText + "\n" + shareLink)}`
  //   );
  // };

  const share = async () => {
    try {
      await navigator.share({
        title: 'Поделиться ссылкой',
        text: shareText,
        url: shareLink,
      });
      console.log('Ссылка успешно отправлена');
    } catch (error) {
      console.error('Ошибка при шеринге: ', error);
    }

  }
  const navigatorShare = navigator.share as ((() => void) | undefined)
  return (
    <AnimatePresence>
      {shareMenu && (
        <motion.div
          initial={{ translateY: -100, opacity: 0 }}
          animate={{ translateY: 0, opacity: 1 }}
          exit={{ translateY: -100, opacity: 0 }}
          transition={{ ease: "easeInOut" }}
          className="notification"
        >
          <button
            onClick={() => setShareMenu(false)}
            className="close-notification"
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.2501 18.7498L18.7501 31.2498M18.7501 18.7498L31.2501 31.2498M45.8334 24.9998C45.8334 36.5058 36.506 45.8332 25.0001 45.8332C13.4941 45.8332 4.16675 36.5058 4.16675 24.9998C4.16675 13.4939 13.4941 4.1665 25.0001 4.1665C36.506 4.1665 45.8334 13.4939 45.8334 24.9998Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
          <div className="video-task-share-menu">
            <h5>{text.share}</h5>
            <button onClick={copyShareLink} className="video-task-share-button">
              <Icon icon={copy ? "copy_done" : "copy"} />
              {copy ? "Copied" : "Copy Link"}
            </button>
            <button onClick={TelegramShare} className="video-task-share-button">
              <Icon icon="tg" />
              Telegram
            </button>
            {navigatorShare && <button onClick={share} className="video-task-share-button share-variants">
              <div> 
                <Icon icon="share_whatsapp" />
              </div>
              <div>
                <Icon icon="share_viber" />
              </div>
              <div>
                <Icon icon="share_x" />
              </div>
              <div>
                <Icon icon="share_email" />
              </div>
            </button>}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default VideoTaskShare;
