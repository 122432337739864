import { Outlet, useNavigate } from "react-router-dom";
import "./styles/main.css";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "utils/app-context";
import tg from "utils/tg";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { MainContext } from "App";

const Main = () => {
  const { redicrectVideoTask, user, updateTasks,t } = useContext(AppContext);
  const navigate = useNavigate();
  const { setNotification } = useContext(MainContext);
  const [fetching, setfetching] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const text = t('walletPage')
  const isWalletConnected = tonConnectUI.connected;
  useEffect(() => {
    if (!userFriendlyAddress.startsWith("U") && isWalletConnected) {
      tonConnectUI.disconnect();
      setNotification({
        text: text.walletTestnetWarning,
      });
      return;
    }
  }, [userFriendlyAddress,isWalletConnected]);

  useEffect(() => {
    if (user && typeof redicrectVideoTask.current === "number" && !fetching) {
      console.log(
        "🚀 ~ useEffect ~ !user || !redicrectVideoTask.current:",
        user,
        redicrectVideoTask.current
      );
      setfetching(true);
      updateTasks()
        .then((res) => {
          if (!res) return;
          const task = res?.find(
            ({ id }) => id === String(redicrectVideoTask.current)
          );
          console.log("🚀 ~ updateTasks ~ task:", task);

          if (!task) {
            tg.showAlert(
              "We can't find the video-task that was shared with you, maybe it is not available to you"
            );
          } else navigate("/main/video-task", { state: task });

          redicrectVideoTask.current = true;
        })
        .finally(() => {
          setfetching(false);
        });
    }
  }, [user]);
  return <Outlet />;
};

export default Main;
