import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import './upgrade-cart-standart.css'
import Product from 'types/product'
import { AnimatePresence, motion } from 'framer-motion'
import buyProduct from 'api/buy-product'
import Icon from 'UI/Icon'
import formatNum from 'utils/format-num'
import Img from 'UI/Img'
import { CHAIN, SendTransactionRequest, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import AppContext from 'utils/app-context'
import tg from 'utils/tg'
import { useNavigate } from 'react-router-dom'
import { MainContext } from 'App'
import createTransaction from 'api/create-transaction'
import createStarTransaction from 'api/create-star-transaction'
import { beginCell, Cell} from 'ton'
import {v4 } from 'uuid';

type Props = {
  product:Product | null
  setProduct:Dispatch<SetStateAction<Product | null>>
  refreshList:() => void
}
// const body = beginCell()
//   .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
//   .storeStringTail("Ahahahahahhahahahahhahahahahahhhaha") // write our text comment
//   .endCell();
//   console.log(body.toBoc().toString("base64"));
// const boc = "te6ccgEBBAEA1gABRYgAs/T+GR+2ZgexE7wftHC1nkvPCwoyf0yq5BBv58r2UZYMAQGcAAiUDRMTicTp7u6T1xtuGGWKQ13LNHwFDxJLFDYtBBBG0utVkRPehD6lFrZvcWnArxCcaH5eliYWsE6hv5pzBympoxdn3W6rAAAADQADAgFmQgBYBcBxcfs5gSL1ga8PMgZyb5C7hfpkS3uBd+veC5W0k5zEtAAAAAAAAAAAAAAAAAABAwBOAAAAAEFoYWhhaGFoYWhoYWhhaGFoYWhoYWhhaGFoYWhhaGhoYWhh"
// console.log("🚀 ~ txHash:", parseBOC(boc)) 

// const test = tonweb.boc.Cell.fromBoc("te6ccgEBBAEA1gABRYgAs/T+GR+2ZgexE7wftHC1nkvPCwoyf0yq5BBv58r2UZYMAQGcAAiUDRMTicTp7u6T1xtuGGWKQ13LNHwFDxJLFDYtBBBG0utVkRPehD6lFrZvcWnArxCcaH5eliYWsE6hv5pzBympoxdn3W6rAAAADQADAgFmQgBYBcBxcfs5gSL1ga8PMgZyb5C7hfpkS3uBd+veC5W0k5zEtAAAAAAAAAAAAAAAAAABAwBOAAAAAEFoYWhhaGFoYWhoYWhhaGFoYWhoYWhhaGFoYWhhaGhoYWhh")
// console.log("🚀 ~ test:", test.toString())

const UpgradeModal = ({product,setProduct,refreshList}: Props) => {
  const [loading, setloading] = useState(false)
  const {t} = useContext(AppContext)
  const errors = t('errors')
  const text = t('upgradePage')
  const {setNotification} = useContext(MainContext)
  const [tonConnectUI] = useTonConnectUI();
  const navigate = useNavigate()

  const handleTonTransaction = async (id:Product['id']) => {
    if (isAvailable) {
      if(product?.unavailable_reason) {
        setNotification({text:product?.unavailable_reason})
      }
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    setloading(true)
    if (!tonConnectUI.connected) {
      tg.showConfirm(errors.walletNotConnected,(ok) => {
        if(ok) {
          navigate('/main/wallet')
          tonConnectUI.openModal()
        }
      })
      setloading(false)
      return      
    }
    const check_token = v4()
    const body = beginCell()
    .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
    .storeStringTail(check_token) // write our text comment
    .endCell();

    const createResult = await createTransaction(id,check_token)
    if (!createResult || createResult.error) {
      tg.showAlert(createResult?.error || errors.default)
      return
    }
  
   try {
    

    const transaction:SendTransactionRequest = {
      messages: [
          {
              address: createResult.address,
              amount: String(createResult.amount * 1000000000),
              payload: body.toBoc().toString("base64")
          }
      ],
        validUntil:Math.floor(Date.now() / 1000) + createResult.seconds,
      }
      const result = await tonConnectUI.sendTransaction(transaction)
      console.log("🚀 ~ handleTonTransaction ~ result:", result)
      isAvailable = false
      await new Promise((resolve) => setTimeout(resolve, 5000))
      await handleBuyProduct(check_token)

   } catch (error) {
    console.log("🚀 ~ handleTonTransaction ~ error:", error)
    tg.showAlert(error as string)
   }
   setloading(false)
  }

  const handleStarsTransaction = async () => {
    if(!product) return
    if (isAvailable) {
      if(product?.unavailable_reason) {
        setNotification({text:product?.unavailable_reason})
      }
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    setloading(true)
    const transaction = await createStarTransaction(product.id)
    if(transaction?.link) {
      tg.openInvoice(transaction?.link, async (status) => {
          if (status === 'paid') {
            await refreshList()
            setProduct(null)
            if (product?.alert_after_buy) setNotification({text:product?.alert_after_buy})
            setloading(false)
          } else tg.showAlert(errors.default)
      })
    }
    setloading(false)
  }

  const handleBuyProduct = async (check_token?:string) => {
    if (isAvailable) {
      if(product?.unavailable_reason) {
        setNotification({text:product?.unavailable_reason})
      }
      tg.HapticFeedback.notificationOccurred('error')
      return
    }
    setloading(true)
    const result = await buyProduct(product?.id || '',check_token)
    if (result?.result) {
      await refreshList()
      setProduct(null)
      if (product?.alert_after_buy) setNotification({text:product?.alert_after_buy})
      setloading(false)
    } else tg.showAlert(errors.default)
  }

  let isAvailable = !Boolean(product?.available)
  return (
    <AnimatePresence>
    {product && <motion.div 
        initial={{translateY:-100,opacity:0}}
        animate={{translateY:0,opacity:1}}
        exit={{translateY:-100,opacity:0}}
        transition={{ease:'easeInOut'}}
        key={product?.id}
        className={`upgrade-modal-screen ${product.category ? 'bg-2' : ''}`}
      >
        <div style={{marginBottom:10}}>
          <span className="upgrade-tag">
            {product.price_coins ? 'Standart' : 'VIP'}
          </span>
        </div>
        <div className="up-standart-header">
            <h2 className="title">{product.title}</h2>
            <svg onClick={() => setProduct(null)} className="close" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.75 10.25L10.25 17.75M10.25 10.25L17.75 17.75M26.5 14C26.5 20.9036 20.9036 26.5 14 26.5C7.09644 26.5 1.5 20.9036 1.5 14C1.5 7.09644 7.09644 1.5 14 1.5C20.9036 1.5 26.5 7.09644 26.5 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        </div>
        <div className="up-standart-image">
           <Img src={`/img/previews/${product.image}`} size={240}/>
        </div>
        <p className="up-standart-text" dangerouslySetInnerHTML={{__html:product.description}}></p>
        <div className="upgrade-farming">
            <div className="farming-coin">
                <img src="/img/coin4.png" alt=""/>
                <div className="farming-coin-text"><span className="bold">{text.farmItemStat}</span>+{product.additional_farming} / {text.hours}</div>
            </div>
            <div className="farming-rep">
                <div className="farming-rep-text"><span className="green-bold">REP:</span>+{product.additional_rep} <img src="/img/rep.png" className="size-24" alt=""/></div>
            </div>
        </div>
        {
        loading
          ? <div className="up-standart-button"><Icon icon='loading'/></div>
          : <ul className='up-standart-button-list' style={product.category === 3 ? {display:'none'} : {}}>
            
              {!!product.price_coins &&
                <li style={{opacity:!isAvailable ? 1: 0.5}}><div className="up-standart-button" onClick={() => handleBuyProduct()} style={{justifyContent:'space-around'}}> 
                  <div style={{display:'flex',alignItems:'center',gap:5}}>
                    <img src="/img/coin4.png" alt=""/>{formatNum(product.price_coins)}
                    {isAvailable && <img src="/img/lock.svg" style={{width:24,height:24}}/>}
                  </div>
                </div></li>
              }
              {!!product.price_stars &&
                <li style={{opacity:!isAvailable ? 1: 0.5}}><div className="up-standart-button" onClick={handleStarsTransaction} style={{justifyContent:'space-around'}}> 
                <div style={{display:'flex',alignItems:'center',gap:5}}>
                  <img src="/img/star.svg" alt="" />{formatNum(product.price_stars)}
                  {isAvailable && <img src="/img/lock.svg" style={{width:24,height:24}}/>}
                </div>
              </div></li>
              }
              {!!product.price_ton &&
                <li style={{opacity:!isAvailable ? 1: 0.5}}><div className="up-standart-button" onClick={() =>  handleTonTransaction(product.id)} style={{justifyContent:'space-around'}}> 
                <div style={{display:'flex',alignItems:'center',gap:5}}>
                  <img src="/img/rubin.svg" alt=""/>{product.price_ton}
                  {isAvailable && <img src="/img/lock.svg" style={{width:24,height:24}}/>}
                </div>
              </div></li>
              }
          </ul>
        }
        </motion.div>}
    </AnimatePresence>
  )
}

export default UpgradeModal