import { AnimatePresence, motion, Variants } from "framer-motion";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Task from "types/task";
import Icon from "UI/Icon";
import AppContext from "utils/app-context";
import { MainContext } from "App";
import tg from "utils/tg";

type Props = {
  stage: number;
  handleQuestion: (answer: boolean) => void;
  loading: boolean;
  answer: boolean | null;
  setloading: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  task: Task;
  bid:number
  setBid:(answer: number) => void;
};

const VideoTaskQuestion = ({
  stage,
  handleQuestion,
  loading,
  answer,
  setloading,
  disabled,
  task,
  bid,
  setBid
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false)
  const [bottom, setBottom] = useState(0);
  const { user, t } = useContext(AppContext);
  const { setNotification } = useContext(MainContext);
  const text = t("videoTasks");
  useEffect(() => {
    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      setBottom(height - 35);
    }
  }, [stage]);
  const handleAnswer = (a: boolean) => {
    if (disabled || !user) return;
    if (user?.balance < task.bid_min) {
      setNotification({ text: text.notEnogthCoins });
      return;
    }
    handleQuestion(a);
    setloading(true);
  };
  const showHelpHint = () => {
    if(!user) return
    setNotification({text:user?.videotask_help_text})
  }

  const summ = Number(task.bids_n) + Number(task.bids_y);
  const percentY = Math.round((Number(task.bids_y) / summ) * 1000) / 10;
  const percentN = Math.round((Number(task.bids_n) / summ) * 1000) / 10;
  if(!user) return null

  const hasBeenAnswered = typeof task.answer === 'number'
  return (
    // <motion.div
    //   animate={{
    //     height: opened ? 35 : 0,
    //     position:'absolute',
    //     bottom:88,
    //     left:0,
    //     width:'100%'
    //   }}
    //   className="video-task-question-container"
    // >
    //   <motion.div
    //     initial={{ y: opened ? 0 : "100%" }}
    //     animate={{ y: opened ? 0 : "100%" }}
    //     className="video-task-question"
    //   >
    <motion.div
      drag="y"
      ref={ref}
      initial={{ y: stage !== 1 ? 0 : "100%" }}
      onPointerDown={(e) => e.stopPropagation()}
      animate={{ y: stage !== 1 ? 0 : "100%" }}
      dragConstraints={{ top: 0, bottom: bottom }}
      className="video-task-question-body"
    >
      <hr />
      {stage === 0 && (
        <>
          <p className="video-question-info" dangerouslySetInnerHTML={{__html:text.whatchVideo_html}}/>
          <div className="video-question-info-rep">
            <img src="/img/rep.png" className="size-24" alt=""/>
            <span className="main-level-info-rep">+{task.reward}</span>
          </div>
        </>
      )}
      {stage === 2 && (<>
      {!hasBeenAnswered && <div className="voice-power" data-opened={opened}>
        <AnimatePresence>
        {!opened && <motion.h3 {...opacityVariants}>Voice Power</motion.h3>}
       {!opened && <motion.div {...opacityVariants} className="voice-power-actions">
          <button onClick={showHelpHint} className="voice-power-actions--help"/>
          <img src="\img\coin3.png" alt=""/>
          <p>{bid}</p>
          <button onClick={() => setOpened(true)} className="voice-power-actions--bid"/>
        </motion.div>}
        {opened && <motion.div {...opacityVariants} className="voice-power-bid-settings">
          <div className="voice-power-bid-settings-container">
            <button onClick={() => {
              if(bid-1 < task.bid_min) return
              setBid(bid-1)
            }}><Icon icon="circleSubtraction"/></button>
            <img src="\img\coin3.png" alt=""/>
            <p>{bid}</p>
            <button onClick={() => {
              if(user?.balance < bid+1) return
              setBid(bid+1)

            }}><Icon icon="circleAdd"/></button>
          </div>
          <p>Minimum value is {task.bid_min} coins</p>
          <button onClick={() => setOpened(false)} className="voice-power-bid-confirm">Confirm</button>
          <p className="voice-power-bid-desc">The greater the strength of your opinion, the more coins you will receive if it agrees with the majority.</p>
        </motion.div>}
        </AnimatePresence>
      </div>}
      <div className="video-question">
        <p className="video-question-info" dangerouslySetInnerHTML={{__html:task.question}}>
        </p>
        <div className="video-question-buttons" style={hasBeenAnswered ? {pointerEvents:'none'} :{}}>
          <button onClick={() => handleAnswer(true)} data-selected={(task.answer && task.answer !== null)}>
          {answer && loading
                ? <Icon icon="loading" />
                : <span  className={percentN < percentY ? 'bigger' : ''}><span>Y</span>{task.status === 1 && <span> &nbsp;{`${percentY}%`}</span>}</span>
              }
          </button>
          <button onClick={() => handleAnswer(false)} data-selected={(!task.answer && task.answer !== null)}>
          {!answer && loading
                ? <Icon icon="loading" />
                : <span className={percentN > percentY ? 'bigger' : ''}><span>N</span>{task.status === 1 && <span> &nbsp;{`${percentN}%`}</span>}</span>
              }

          </button>
        </div>
      </div>
      </>)}
    </motion.div>
    //   </motion.div>
    // </motion.div>
  );
};

const opacityVariants:Variants = {
  initial:{
    opacity:0,
  },
  animate:{
    opacity:1,
  },
  exit:{
    opacity:0,
  }
}

export default VideoTaskQuestion;
