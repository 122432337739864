import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
    icon:keyof typeof icons
}

const Icon = (props: Props) => {
  const icon = icons[props.icon]
//   icon.props = props
return icon
}

const icons = {
    home:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 15.2549C3 12.3944 3 10.9641 3.649 9.77843C4.298 8.59276 5.48369 7.85689 7.85504 6.38516L10.355 4.83359C12.8617 3.27786 14.1151 2.5 15.5 2.5C16.8849 2.5 18.1382 3.27786 20.645 4.83359L23.145 6.38515C25.5164 7.85689 26.702 8.59276 27.351 9.77843C28 10.9641 28 12.3944 28 15.2549V17.1563C28 22.0323 28 24.4704 26.5355 25.9851C25.0711 27.5 22.714 27.5 18 27.5H13C8.28595 27.5 5.92894 27.5 4.46446 25.9851C3 24.4704 3 22.0323 3 17.1563V15.2549Z" stroke="white" strokeWidth="1.5"/>
            <path d="M19.25 22.5H11.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    ),
    upgrage:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5002 20C8.30026 20 7.02479 12.8244 6.79884 7.13309C6.73599 5.54998 6.70456 4.75841 7.29921 4.02604C7.89385 3.29367 8.60556 3.17359 10.029 2.93343C11.434 2.69636 13.2707 2.5 15.5002 2.5C17.7296 2.5 19.5664 2.69636 20.9714 2.93343C22.3949 3.17359 23.1065 3.29367 23.7011 4.02604C24.2959 4.75841 24.2644 5.54998 24.2015 7.13309C23.9756 12.8244 22.7001 20 15.5002 20Z" stroke="white" strokeWidth="1.5"/>
            <path d="M15.5 20V23.75" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M19.875 27.5H11.125L11.549 25.3799C11.6659 24.7956 12.1789 24.375 12.7748 24.375H18.2252C18.8211 24.375 19.3341 24.7956 19.451 25.3799L19.875 27.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.25 6.25L25.4358 6.64526C26.6734 7.05779 27.2921 7.26405 27.646 7.7551C28 8.24615 28 8.89841 27.9999 10.2029V10.2936C27.9999 11.3695 27.9999 11.9075 27.7409 12.3476C27.4819 12.7877 27.0116 13.049 26.0711 13.5715L22.375 15.625" stroke="white" strokeWidth="1.5"/>
            <path d="M6.74993 6.25L5.56415 6.64526C4.32658 7.05779 3.70779 7.26405 3.35388 7.7551C2.99995 8.24615 2.99996 8.89841 3 10.2029V10.2936C3.00004 11.3695 3.00005 11.9075 3.25904 12.3476C3.51801 12.7877 3.98829 13.049 4.92881 13.5715L8.62493 15.625" stroke="white" strokeWidth="1.5"/>
            <path d="M14.4324 7.52814C14.9074 6.67605 15.1449 6.25 15.5 6.25C15.8551 6.25 16.0926 6.67605 16.5676 7.52814L16.6905 7.74859C16.8254 7.99073 16.8929 8.1118 16.9981 8.19167C17.1034 8.27156 17.2344 8.30121 17.4965 8.36051L17.7351 8.41451C18.6575 8.62321 19.1187 8.72756 19.2285 9.0804C19.3382 9.43324 19.0237 9.8009 18.395 10.5362L18.2322 10.7265C18.0536 10.9354 17.9642 11.0399 17.924 11.1691C17.8839 11.2984 17.8974 11.4378 17.9244 11.7166L17.949 11.9704C18.044 12.9515 18.0916 13.442 17.8044 13.6601C17.517 13.8781 17.0852 13.6794 16.2216 13.2817L15.9982 13.1789C15.7527 13.0659 15.6301 13.0094 15.5 13.0094C15.3699 13.0094 15.2472 13.0659 15.0017 13.1789L14.7784 13.2817C13.9147 13.6794 13.483 13.8781 13.1956 13.6601C12.9084 13.442 12.9559 12.9515 13.051 11.9704L13.0756 11.7166C13.1026 11.4378 13.1161 11.2984 13.076 11.1691C13.0357 11.0399 12.9464 10.9354 12.7677 10.7265L12.605 10.5362C11.9762 9.8009 11.6618 9.43324 11.7715 9.0804C11.8813 8.72756 12.3424 8.62321 13.2649 8.41451L13.5035 8.36051C13.7656 8.30121 13.8966 8.27156 14.0019 8.19167C14.1071 8.1118 14.1746 7.99073 14.3095 7.74859L14.4324 7.52814Z" stroke="white" strokeWidth="1.5"/>
            <path d="M23 27.5H8" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    ),
    tasks:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0046 5H12.9954C8.27261 5 5.91124 5 4.44407 6.46446C3.34577 7.56072 3.06963 9.15715 3.0002 11.8681C2.9913 12.2156 3.27652 12.4933 3.61345 12.5815C4.68843 12.8626 5.48152 13.8389 5.48152 15C5.48152 16.1611 4.68843 17.1374 3.61345 17.4185C3.27652 17.5066 2.9913 17.7844 3.0002 18.132C3.06963 20.8429 3.34577 22.4392 4.44407 23.5355C5.91124 25 8.27261 25 12.9954 25H18.0046C22.7274 25 25.0888 25 26.556 23.5355C27.6543 22.4392 27.9304 20.8429 27.9997 18.132C28.0087 17.7844 27.7235 17.5066 27.3865 17.4185C26.3116 17.1374 25.5185 16.1611 25.5185 15C25.5185 13.8389 26.3116 12.8626 27.3865 12.5815C27.7235 12.4933 28.0087 12.2156 27.9997 11.8681C27.9304 9.15715 27.6543 7.56072 26.556 6.46446C25.0888 5 22.7274 5 18.0046 5Z" stroke="white" strokeWidth="1.5"/>
            <path d="M14.4324 12.5281C14.9074 11.6761 15.1449 11.25 15.5 11.25C15.8551 11.25 16.0926 11.6761 16.5676 12.5281L16.6905 12.7486C16.8254 12.9908 16.8929 13.1117 16.9981 13.1916C17.1034 13.2715 17.2344 13.3013 17.4965 13.3605L17.7351 13.4145C18.6575 13.6232 19.1187 13.7275 19.2285 14.0804C19.3382 14.4333 19.0237 14.8009 18.395 15.5362L18.2322 15.7265C18.0536 15.9354 17.9642 16.0399 17.924 16.1691C17.8839 16.2984 17.8974 16.4377 17.9244 16.7166L17.949 16.9704C18.044 17.9515 18.0916 18.442 17.8044 18.6601C17.517 18.8781 17.0852 18.6794 16.2216 18.2817L15.9982 18.1789C15.7527 18.0659 15.6301 18.0094 15.5 18.0094C15.3699 18.0094 15.2472 18.0659 15.0017 18.1789L14.7784 18.2817C13.9147 18.6794 13.483 18.8781 13.1956 18.6601C12.9084 18.442 12.9559 17.9515 13.051 16.9704L13.0756 16.7166C13.1026 16.4377 13.1161 16.2984 13.076 16.1691C13.0357 16.0399 12.9464 15.9354 12.7677 15.7265L12.605 15.5362C11.9762 14.8009 11.6618 14.4333 11.7715 14.0804C11.8813 13.7275 12.3424 13.6232 13.2649 13.4145L13.5035 13.3605C13.7656 13.3013 13.8966 13.2715 14.0019 13.1916C14.1071 13.1117 14.1746 12.9908 14.3095 12.7486L14.4324 12.5281Z" stroke="white" strokeWidth="1.5"/>
        </svg>
    ),
    friends:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 12.5C15.7614 12.5 18 10.2614 18 7.5C18 4.73858 15.7614 2.5 13 2.5C10.2386 2.5 8 4.73858 8 7.5C8 10.2614 10.2386 12.5 13 12.5Z" stroke="white" strokeWidth="1.5"/>
            <path d="M23 21.875C23 24.9816 23 27.5 13 27.5C3 27.5 3 24.9816 3 21.875C3 18.7684 7.47715 16.25 13 16.25C18.5229 16.25 23 18.7684 23 21.875Z" stroke="white" strokeWidth="1.5"/>
            <path id='friends-heart-icon' d="M20.5678 14.3715C20.9849 14.8414 21.4957 15.2351 21.9523 15.5868C22.0565 15.6671 22.1579 15.7452 22.2547 15.8216L22.2597 15.8255C22.4446 15.9713 22.6381 16.1222 22.8322 16.2357C23.028 16.3502 23.2415 16.4371 23.4677 16.4371C23.6939 16.4371 23.9073 16.3503 24.103 16.2358C24.2972 16.1223 24.4906 15.9713 24.6756 15.8255M20.5678 14.3715C20.0488 13.7868 19.6572 13.0671 19.6572 12.1243C19.6572 11.1679 20.1981 10.346 20.9675 9.99413C21.6025 9.70373 22.356 9.74451 23.0713 10.2265L23.4677 10.4936L23.8641 10.2265C24.5795 9.74451 25.3329 9.70372 25.968 9.99412C26.7373 10.3459 27.2782 11.1679 27.2782 12.1243C27.2782 13.0671 26.8866 13.7868 26.3676 14.3715C25.9505 14.8414 25.4397 15.2351 24.9831 15.5868C24.8789 15.6671 24.7775 15.7452 24.6807 15.8216L24.6756 15.8255M20.5678 14.3715L23.4677 16.7953L24.6756 15.8255" stroke="white"/>
        </svg>
    ),
    wallet:(
        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 10H13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.5416 11.25H23.2885C21.0581 11.25 19.25 12.9289 19.25 15C19.25 17.0711 21.0581 18.75 23.2885 18.75H26.5416C26.6459 18.75 26.6979 18.75 26.7419 18.7474C27.416 18.7063 27.9529 18.2078 27.9971 17.5818C28 17.5409 28 17.4925 28 17.3959V12.6041C28 12.5075 28 12.4591 27.9971 12.4182C27.9529 11.7923 27.416 11.2937 26.7419 11.2527C26.6979 11.25 26.6459 11.25 26.5416 11.25Z" stroke="white" strokeWidth="1.5"/>
            <path d="M26.7063 11.25C26.6091 8.90962 26.2957 7.47469 25.2855 6.46446C23.8211 5 21.464 5 16.75 5H13C8.28595 5 5.92894 5 4.46446 6.46446C3 7.92894 3 10.286 3 15C3 19.714 3 22.0711 4.46446 23.5355C5.92894 25 8.28595 25 13 25H16.75C21.464 25 23.8211 25 25.2855 23.5355C26.2957 22.5254 26.6091 21.0904 26.7063 18.75" stroke="white" strokeWidth="1.5"/>
            <path d="M22.989 15H23.0003" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    close:(
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M18.75 11.25L11.25 18.75M11.25 11.25L18.75 18.75M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
              ></path>
            </svg>
    ),
    timer:(
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_6620_7467)">
        <path d="M6.99984 4.00008V7.50008H9.62484M12.8332 7.50008C12.8332 10.7217 10.2215 13.3334 6.99984 13.3334C3.77818 13.3334 1.1665 10.7217 1.1665 7.50008C1.1665 4.27842 3.77818 1.66675 6.99984 1.66675C10.2215 1.66675 12.8332 4.27842 12.8332 7.50008Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_6620_7467">
        <rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    ),
    video:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2Z" stroke="white" strokeWidth="1.5"/>
            <path d="M21.5 17H2.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M21.5 7H2.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M12 2V7M12 22V17" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M17 2.5V7M17 21.5V17" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M7 2.5V7M7 21.5V17" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14 12C14 11.4722 13.4704 11.1162 12.4112 10.4043C11.3375 9.68271 10.8006 9.3219 10.4003 9.58682C10 9.85174 10 10.5678 10 12C10 13.4322 10 14.1483 10.4003 14.4132C10.8006 14.6781 11.3375 14.3173 12.4112 13.5957C13.4704 12.8838 14 12.5278 14 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
    ),
    hint:(
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="white" strokeWidth="0.5"/>
        <path d="M12.6562 11.0938C12.6562 9.79934 13.7056 8.75 15 8.75C16.2944 8.75 17.3438 9.79934 17.3438 11.0938C17.3438 11.9531 16.8812 12.7044 16.1917 13.1124C15.5975 13.4639 15 13.9971 15 14.6875V16.25" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M15 21.25C15.6904 21.25 16.25 20.6904 16.25 20C16.25 19.3096 15.6904 18.75 15 18.75C14.3096 18.75 13.75 19.3096 13.75 20C13.75 20.6904 14.3096 21.25 15 21.25Z" fill="white"/>
        </g>
        </svg>
    ),
    share:(
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.4909 10.0396L23.3071 8.14326C21.7339 6.77691 21.1171 6.0419 20.2651 6.30174C19.2027 6.62574 19.5525 8.67011 19.5525 9.3603C17.9008 9.3603 16.1835 9.23326 14.5554 9.49795C9.18066 10.3718 7.5 14.1958 7.5 18.3156C9.02121 17.3831 10.5409 16.3433 12.3693 15.9111C14.6515 15.3716 17.2004 15.629 19.5525 15.629C19.5525 16.3193 19.2027 18.3636 20.2651 18.6876C21.2305 18.982 21.7339 18.2124 23.3071 16.8461L25.4909 14.9498C26.8303 13.7866 27.5 13.205 27.5 12.4947C27.5 11.7844 26.8303 11.2028 25.4909 10.0396Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.2095 3.75C8.38419 3.75868 5.85742 3.8769 4.24264 5.48841C2.5 7.22752 2.5 10.0266 2.5 15.6246C2.5 21.2227 2.5 24.0217 4.24262 25.7609C5.98526 27.5 8.78997 27.5 14.3994 27.5C20.0089 27.5 22.8136 27.5 24.5563 25.7609C25.7695 24.55 26.138 22.8254 26.25 20" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
     arrowTop:(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 15L12 9L6 15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    circleSubtraction:(
      <svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none">
      <path
        stroke="#9C59CC"
        strokeWidth={1.5}
        d="M20.5 36.667c9.205 0 16.667-7.462 16.667-16.667 0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.205 7.462 16.667 16.667 16.667Z"
      />
      <path
        stroke="#9C59CC"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M25.5 20h-10"
      />
    </svg>
    ),
    share_email:(
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M20 4.5H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-12a2 2 0 0 0-2-2Z"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m22 7.5-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7.5"
        />
      </svg>
    ),
    share_x:(
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
        <mask
          id="a"
          width={20}
          height={21}
          x={2}
          y={2}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path fill="#fff" d="M2 2.5h20v20H2v-20Z" />
        </mask>
        <g mask="url(#a)">
          <path
            fill="#fff"
            d="M17.75 3.437h3.067l-6.7 7.677L22 21.563h-6.171l-4.838-6.336-5.528 6.336h-3.07l7.166-8.214L2 3.438h6.329l4.365 5.79 5.056-5.792Zm-1.079 16.286h1.7L7.4 5.18H5.577l11.094 14.542Z"
          />
        </g>
      </svg>
    ),
    share_viber:(
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
        <path
          fill="#fff"
          d="M7.965 6.702a.82.82 0 0 0-.537.106h-.014c-.375.22-.713.497-1.001.823-.24.277-.37.557-.404.827-.02.16-.006.322.041.475l.018.01c.27.793.622 1.556 1.052 2.274a13.401 13.401 0 0 0 2.03 2.775l.024.034.038.028.023.027.028.024c.837.795 1.772 1.48 2.782 2.04 1.155.629 1.856.926 2.277 1.05v.006c.123.038.235.055.348.055a1.6 1.6 0 0 0 .964-.414c.325-.288.6-.627.814-1.004v-.007c.201-.38.133-.738-.157-.981a11.999 11.999 0 0 0-1.881-1.35c-.448-.243-.903-.096-1.087.15l-.393.496c-.202.246-.568.212-.568.212l-.01.006c-2.731-.697-3.46-3.462-3.46-3.462s-.034-.376.219-.568l.492-.396c.236-.192.4-.646.147-1.094a12 12 0 0 0-1.347-1.88.75.75 0 0 0-.44-.263M12.58 5.5a.5.5 0 0 0 0 1c1.264 0 2.314.413 3.145 1.205.427.433.76.946.978 1.508.219.563.319 1.164.293 1.766a.5.5 0 1 0 1 .042 5.402 5.402 0 0 0-1.565-4.024l-.01-.01C15.39 6.002 14.086 5.5 12.58 5.5Z"
        />
        <path
          fill="#fff"
          d="M12.545 7.144a.5.5 0 0 0 0 1h.017c.912.065 1.576.369 2.041.868.477.514.724 1.153.705 1.943a.5.5 0 1 0 1 .023c.024-1.037-.31-1.932-.972-2.646V8.33c-.677-.726-1.606-1.11-2.724-1.185l-.017-.002-.05.001Z"
        />
        <path
          fill="#fff"
          d="M12.526 8.819a.5.5 0 1 0-.052.998c.418.022.685.148.853.317.169.17.295.443.318.87a.5.5 0 1 0 .998-.053c-.032-.6-.22-1.13-.605-1.52-.387-.39-.914-.58-1.512-.612Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.067 2.884a22.15 22.15 0 0 1 9.664 0l.339.075a5.16 5.16 0 0 1 3.872 3.763 19.702 19.702 0 0 1 0 9.7 5.16 5.16 0 0 1-3.872 3.763l-.34.075a22.2 22.2 0 0 1-6.077.499L8 23.133a.75.75 0 0 1-1.24-.435l-.439-2.622a5.161 5.161 0 0 1-3.465-3.654 19.7 19.7 0 0 1 0-9.7 5.16 5.16 0 0 1 3.872-3.763l.339-.075Zm9.337 1.463a20.65 20.65 0 0 0-9.01 0l-.34.076A3.66 3.66 0 0 0 4.31 7.091a18.2 18.2 0 0 0 0 8.962 3.66 3.66 0 0 0 2.745 2.668l.09.02a.75.75 0 0 1 .576.608l.294 1.758 1.872-1.675a.75.75 0 0 1 .553-.19 20.704 20.704 0 0 0 5.964-.445l.339-.076a3.66 3.66 0 0 0 2.745-2.668c.746-2.94.746-6.021 0-8.962a3.66 3.66 0 0 0-2.745-2.668l-.339-.076Z"
          clipRule="evenodd"
        />
      </svg>
    ),
    share_whatsapp:(
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
        <path
          fill="#fff"
          d="M19.05 5.41a9.82 9.82 0 0 0-7.01-2.91c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95l-1.4 5.14 5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01Zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24 2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23Zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23-.74-.66-1.23-1.47-1.38-1.72-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18-.07-.11-.22-.16-.47-.28Z"
        />
      </svg>
    ),
    tg:(
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#fff"
      strokeWidth={0.8}
      d="M13.927 10.303c-.088.02-1.701.93-4.425 2.768-.399.274-.76.408-1.084.4-.357-.007-1.044-.201-1.555-.367a26.004 26.004 0 0 0-.218-.07c-.52-.163-.9-.283-.862-.587.022-.18.27-.364.744-.553 2.915-1.27 4.859-2.107 5.831-2.512 2.777-1.155 3.354-1.355 3.73-1.362.083-.001.268.02.388.116a.422.422 0 0 1 .143.271c.013.078.03.255.016.394-.15 1.581-.802 5.418-1.133 7.19-.14.749-.416 1-.683 1.024-.46.043-.832-.223-1.247-.518-.108-.078-.22-.157-.337-.234a73.91 73.91 0 0 1-1.17-.785c-.327-.222-.654-.445-1.062-.714-.85-.56-.494-.903-.02-1.36.077-.074.157-.151.235-.233.035-.036.207-.195.448-.42.774-.72 2.224-2.287 2.261-2.448Zm0 0c.063-.014-.058-.052 0 0Zm0 0c.059.052.007-.026 0 0ZM22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
    />
  </svg>
    ),
    copy_done:(
        <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
        <path
          stroke="#9C59CC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M5.5 14.5 9 18 19.5 7"
        />
      </svg>
    ),
    copy:(
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4 16.5c-1.1 0-2-.9-2-2v-10c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2m-6 4h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-10a2 2 0 0 1 2-2Z"
        />
      </svg>
    ),
    circleAdd:(
      <svg xmlns="http://www.w3.org/2000/svg" width={41} height={40} fill="none">
      <path
        stroke="#9C59CC"
        strokeWidth={1.5}
        d="M20.5 36.667c9.205 0 16.667-7.462 16.667-16.667 0-9.205-7.462-16.667-16.667-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.205 7.462 16.667 16.667 16.667Z"
      />
      <path
        stroke="#9C59CC"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M25.5 20h-5m0 0h-5m5 0v-5m0 5v5"
      />
    </svg>
    ),
    loading:(
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128" ><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" fill="#ffffff"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"></animateTransform></g></svg>
    )
}

export default Icon