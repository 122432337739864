import { useContext, useEffect, useRef, useState, useMemo } from 'react';
import PageWrapper from 'UI/PageWrapper';
import './tasks.css';
import TaskModal from './TaskModal/TaskModal';
import TaskCard from './TaskCard';
import { AnimatePresence, motion } from 'framer-motion';
import Icon from 'UI/Icon';
import AppContext from 'utils/app-context';
import Task from 'types/task';
import VideoTasksFilter from './VideoTasksFilter';
import Tag from 'types/tag';
import { useSearchParams } from 'react-router-dom';

const FILTERS = {
    VIDEO: 'video',
    ACTIVE: 'active',
    COMPLETED: 'completed',
};

const Tasks = () => {
    const [openedTask, setOpenedTask] = useState<Task | null>(null);
    const [params] = useSearchParams()
    const type = params.get('type')
    const [filter, setFilter] = useState(type || FILTERS.VIDEO);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const { t, settasks, tasks, updateTasks } = useContext(AppContext);
    const text = t('tasksPage');
    const [filterTags, setfilterTags] = useState<Tag[]>([])
    useEffect(() => {
        updateTasks();
    }, []);
    useEffect(() => {
        if (!tasks) return;
        if (timer.current) clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            settasks((prev) => {
                if (!prev) return prev;
                return prev.map((t) => ({ ...t, seconds: String(Number(t.seconds) - 1) }));
            });
        }, 1000);

        return () => timer.current ? clearTimeout(timer.current): undefined;
    }, [tasks]);

    const filteredTasks = useMemo(() => {
        if (!tasks) return null;

        const filtered = tasks.filter(({ status, is_video }) => {
            const isVideo = Number(is_video) === 1;
            if (filter === FILTERS.VIDEO) return isVideo && !status;
            if (filter === FILTERS.COMPLETED) return status === 1 || (isVideo && status !== 0);
            return !isVideo && (status === 0 || status === 2);
        });
        if (FILTERS.COMPLETED) {
            filtered.sort((a, b) => {
                const isAAlreadyToClaim = Boolean(a.vote_reward && !a.is_claimed);
                const isBAlreadyToClaim = Boolean(b.vote_reward && !b.is_claimed);
        
                const isAGold = Number(a.is_gold_task) === 1;
                const isBGold = Number(b.is_gold_task) === 1;
        
                // 1. Золотые задания, которые можно забрать, идут первыми
                if (isAGold && isAAlreadyToClaim && !(isBGold && isBAlreadyToClaim)) return -1;
                if (!(isAGold && isAAlreadyToClaim) && isBGold && isBAlreadyToClaim) return 1;
        
                // 2. Обычные задания, которые можно забрать, идут следом
                if (isAAlreadyToClaim && !isAGold && !(isBAlreadyToClaim && !isBGold)) return -1;
                if (!(isAAlreadyToClaim && !isAGold) && isBAlreadyToClaim && !isBGold) return 1;
        
                // 3. Затем идут все золотые задания
                if (isAGold && !isBGold) return -1;
                if (!isAGold && isBGold) return 1;
        
                // 4. Остальные в конце
                return 0;
            });
        }
        
        return filtered
    }, [tasks, filter]);

    return (
        <>
            <PageWrapper>
                <div className="tasks-screen">
                    <h2 className="tasks-title-h2">{text.title}</h2>
                    <div className="tasks-types">
                        {Object.entries(FILTERS).map(([key, value]) => {
                            return (
                                <div
                                    key={value}
                                    className={`task-filter ${filter === value ? 'active' : 'active type-desactive'}`}
                                    onClick={() => setFilter(value)}
                                >
                                    {text[(value as keyof typeof text)]}
                                </div>
                            )
                        })}
                    </div>
                    <div className='tasks-items tasks-items-active'>
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={filter}
                                initial={{ opacity: 0, translateX: 50 }}
                                animate={{ opacity: 1, translateX: 0 }}
                                exit={{ opacity: 0, translateX: -50 }}
                                transition={{ duration: 0.1 }}
                                style={{ display: 'flex', flexDirection: 'column', rowGap: 14,alignItems:'center' }}
                            >
                                {filter === FILTERS.VIDEO && <VideoTasksFilter selectedTags={filterTags} setSelectedTags={setfilterTags}/>}
                                {filteredTasks ? (
                                    filteredTasks.length > 0 ? (
                                        filteredTasks.map((task) => {
                                            if(filter === FILTERS.VIDEO && filterTags.length) {
                                                const inFilter = filterTags.findIndex(t => task.tags.includes(t.id))
                                                if(inFilter === -1) return null
                                            }
                                            return (
                                                <TaskCard key={task.id} setopenedTask={setOpenedTask} task={task} />
                                            )
                                        })
                                    ) : (
                                        <p>{text.allCompleted}</p>
                                    )
                                ) : (
                                    <Icon icon="loading" />
                                )}
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </PageWrapper>
            <TaskModal task={openedTask} openedTask={setOpenedTask} refreshTasks={updateTasks} />
        </>
    );
};

export default Tasks;
