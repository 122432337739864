import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import "./friends.css";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";
import tg from "utils/tg";
import Product from "types/product";
import { AnimatePresence, motion } from "framer-motion";
import Icon from "UI/Icon";
import TaskCard from "../Tasks/TaskCard";
import getRating from "api/get-rating";
import RatingData from "types/rating";
import RatingTimer from "./components/RatingTimer";
const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink =
  process.env.REACT_APP_SHARE_LINK?.replace("TG_ID", String(tg_id)) ||
  process.env.REACT_APP_SHARE_LINK ||
  "";

const Friends = () => {
  const { user, t } = useContext(AppContext);
  const [rating, setrating] = useState<RatingData | null>(null);
  const [category, setCategory] = useState<Product["category"]>(1);

  const text = t("frensPage");
  const copyShareLink = (e: React.MouseEvent) => {
    e.currentTarget.textContent = text.linkCopied;
    navigator.clipboard.writeText(inviteLink);
  };
  const TelegramShare = () => {
    tg.openTelegramLink(
      `https://t.me/share/url?url=${inviteLink}&text=${encodeURIComponent(
        text.shareText
      )}`
    );
  };
  const fetchRating = async () => {
    const res = await getRating();
    if (res) setrating(res);
  };
  useEffect(() => {
    fetchRating();
  }, [category]);
  return (
    <PageWrapper className="friends-screen">
      <h2 className="tasks-title-h2">{text.pageTitle}</h2>
      <div className="upgrade-types">
        <div
          className={`standart ${category !== 1 && "type-desactive"}`}
          onClick={() => setCategory(1)}
        >
          {text.rating}
        </div>
        <div
          className={`vip ${category !== 2 && "type-desactive"}`}
          onClick={() => setCategory(2)}
        >
          {text.friends}
        </div>
        <div
          className={`vip ${category !== 3 && "type-desactive"}`}
          onClick={() => setCategory(3)}
        >
          {text.funClub}
        </div>
      </div>
      <div
        className="tasks-items tasks-items-active community-screen"
        style={{ padding: 10 }}
      >
        <AnimatePresence mode="wait">
          {category === 1 && (
            <motion.div
              key={category}
              initial={{ opacity: 0, translateX: 50 }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: -50 }}
              transition={{ duration: 0.1 }}
              style={{ width: "100%" }}
            >
              {rating && (
                <RatingTimer seconds={rating.seconds} onEnd={fetchRating} />
              )}
              <div className="rating-list">
                {!rating && <Icon icon="loading" />}
                {rating &&
                  rating.top_list.map(
                    ({ coins, name, number, photo, rep, win }, i) => {
                      return (
                        <li
                          className="rating-list-item"
                          data-my-pos={i === rating.my}
                        >
                          <span className="rating-pos">{number}</span>
                          <img className="rating-avatar" src={photo} alt="" />
                          <div className="rating-info">
                            <div className="rating-info--name">{name}</div>
                            <div className="rating-info--stats">
                              <p className="rating-stats-win">
                                WIN Y&N: {formatLargeNumber(win)}
                              </p>
                              <div className="rating-stats-block">
                                <img src="/img/coin4.png" />+{" "}
                                {formatLargeNumber(coins)}
                              </div>
                              <div className="rating-stats-block">
                                <img src="/img/rep.png" />+{" "}
                                {formatLargeNumber(rep)}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  )}
              </div>
            </motion.div>
          )}

          {category === 2 && (
            <motion.div
              key={category}
              initial={{ opacity: 0, translateX: 50 }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: -50 }}
              transition={{ duration: 0.1 }}
              style={{ display: "flex", flexDirection: "column", rowGap: 14 }}
            >
              <p
                className="friends-text"
                dangerouslySetInnerHTML={{ __html: user?.friends_text || "" }}
              ></p>
              <div className="total-referral">
                {text.totalIncome}
                <div className="total-money">
                  <img src="/img/coin4.png" alt="" width="30px" height="30px" />
                  +{" "}
                  {user?.friends_list &&
                    formatNum(
                      user?.friends_list.reduce(
                        (sum, { coins }) => sum + coins,
                        0
                      ) || 0
                    )}
                </div>
              </div>
              <div className="yourf">
                {text.yourFrens} ({user?.friends}):
              </div>
              <div className="friends-items">
                {user?.friends_list.map(({ coins, name, photo }, i) => (
                  <div className="friend-item" key={i}>
                    <div className="fried-info">
                      <img src={photo} alt="" />
                      {name}
                    </div>
                    <div className="plus-money">
                      <img
                        src="/img/coin4.png"
                        alt=""
                        width="30px"
                        height="30px"
                      />
                      + {formatNum(coins)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="link" onClick={copyShareLink}>
                {inviteLink}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 16C2.9 16 2 15.1 2 14V4C2 2.9 2.9 2 4 2H14C15.1 2 16 2.9 16 4M10 8H20C21.1046 8 22 8.89543 22 10V20C22 21.1046 21.1046 22 20 22H10C8.89543 22 8 21.1046 8 20V10C8 8.89543 8.89543 8 10 8Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="friends-button" onClick={TelegramShare}>
                {text.buttonInviteText}
              </div>
            </motion.div>
          )}
          {category === 3 && (
            <motion.div
              key={category}
              initial={{ opacity: 0, translateX: 50 }}
              animate={{ opacity: 1, translateX: 0 }}
              exit={{ opacity: 0, translateX: -50 }}
              transition={{ duration: 0.1 }}
              style={{ display: "flex", flexDirection: "column", rowGap: 14 }}
            dangerouslySetInnerHTML={{__html:user?.fun_club_text || ''}}
            />
          )}
        </AnimatePresence>
      </div>
      {/*  */}
    </PageWrapper>
  );
};

const formatLargeNumber = (num: number) => {
  return num >= 10000 
    ? (num / 1000).toFixed(0) + "K" 
    : (num >= 1000 ? (num / 1000).toFixed(1).replace(".0", "") + "K" : formatNum(num));
};

export default Friends;
