import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Task from 'types/task'
import Img from 'UI/Img'
import TaskTimer from 'UI/TaskTimer'
import AppContext from 'utils/app-context'
import formatNum from 'utils/format-num'
import { MainContext } from 'App'

type Props = {
    task:Task
    setopenedTask:(t:Task) => void
}
const LIMIT = 40
const TaskCard = ({task,setopenedTask}: Props) => {
    const {setNotification} = useContext(MainContext)
    const navigate = useNavigate()
    const {id,title,reward,icon,subtitle,reward_type,is_gold_task,is_video,tag_title,seconds,status,vote_reward,is_claimed} = task

    return (
        <div
            className={Number(is_gold_task) ? "tasks-item" : 'completed-item'}
            key={id}
            data-available={task.available}
            onClick={() => {
                if(!task.available) {
                    setNotification({text:task.unavailable_reason || ''})
                    return
                }
                Number(is_video) ? navigate('/main/video-task',{state:task}) : setopenedTask(task)
            }}
        >
            <Img src={Number(is_video) ? '/img/tasks/video.svg' : icon} size={33}/>
        <div className="tasks-content">
            <div className="tasks-title">
            {title}
            </div>
            <div className="tasks-subtitle">
                {subtitle.length > LIMIT ? `${subtitle.slice(0,LIMIT)}...` : subtitle}
            </div>
            <div className="tasks-rep">
                {reward_type === 'balance'
                ? <>
                    <img src="/img/coin4.png"/>
                    +{formatNum(reward)} 
                </>
                : <>
                <img src="/img/rep.png" className='size-24'/>
                +{formatNum(reward)}
                </>
            } 
            </div>
        </div>
        {!!Number(is_video) && tag_title &&
            <div className='task-video-attrs'>
                <span className='task-video-tag'>{tag_title}</span>
                {status === 1 ? <p className='result-txt'>{vote_reward && !is_claimed ? 'Claim' : 'Result'}</p> :<TaskTimer notCount seconds={Number(seconds)}/>}
            </div>
        }
        <div className="tasks-arrow">
        <TaskIcon status={task.status} available={task.available}/>
        </div>
    </div>
    )
}
const TaskIcon = ({status,available}:{status:Task['status'],available:1 | 0}) => {
    let icon
    if(!available) return (
        <svg xmlns="http://www.w3.org/2000/svg" width={30} height={31} fill="none">
            <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15 28c6.904 0 12.5-5.596 12.5-12.5S21.904 3 15 3 2.5 8.596 2.5 15.5 8.096 28 15 28ZM15 20.5v-5M15 10.5h.012"
            />
        </svg>
    )
    switch (status) {
        case 0:
            icon = (
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 23L18.75 15.5L11.25 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
            break;
    
            case 1:
                icon = (<svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 15.5L13.75 18L18.75 13M27.5 15.5C27.5 22.4036 21.9036 28 15 28C8.09644 28 2.5 22.4036 2.5 15.5C2.5 8.59644 8.09644 3 15 3C21.9036 3 27.5 8.59644 27.5 15.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>)
                break
            case 2:
                icon = (
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 6.5V14H19.625M26.5 14C26.5 20.9036 20.9036 26.5 14 26.5C7.09644 26.5 1.5 20.9036 1.5 14C1.5 7.09644 7.09644 1.5 14 1.5C20.9036 1.5 26.5 7.09644 26.5 14Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                )
            break

    }

    return icon
} 
export default TaskCard