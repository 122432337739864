import { motion } from 'framer-motion'
import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import Tag from 'types/tag'
import Icon from 'UI/Icon'
import AppContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {
    selectedTags:Tag[]
    setSelectedTags:Dispatch<SetStateAction<Tag[]>>
}

const VideoTasksFilter = ({selectedTags,setSelectedTags}: Props) => {
    const [opened, setopened] = useState(false)
    const {tags} = useContext(AppContext)
  return (
    <div>
        <motion.ul
        initial={false}
         animate={!opened ? {height:0,opacity:0} : {height:'auto',opacity:1}}
        className='video-filter-tags-list'>
            <li data-active={selectedTags.length === 0} onClick={() => setSelectedTags([])}>All</li>
            {tags?.map((tag) => {
                const {id,title} = tag
                const isActive = selectedTags.findIndex(t => t.id === id) !== -1
                const handleClick = () => {
                    if(isActive) {
                        setSelectedTags(prev => prev.filter(t => t.id !== id))
                    } else setSelectedTags(prev => [...prev,tag])
                }
                return (
                    <li onClick={handleClick} data-active={isActive} key={id}>{title}</li>
                )
            })}
        </motion.ul>
        <button data-opened={opened} onClick={() => {
            setopened(!opened)
            tg.HapticFeedback.impactOccurred('soft')
        }} className='video-task-filter-btn'>
       <motion.p
       animate={opened ? {height:0,opacity:0} : {height:'auto',opacity:1}}
       >Show the filter</motion.p>
        <Icon icon='arrowTop'/>
        </button>
    </div>
  )
}

export default VideoTasksFilter