import { motion } from "framer-motion";
import useRewardAnimation from "hooks/use-reward-animation";
import React, { useContext, useEffect } from "react";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";

type Props = {
  answer: boolean;
  TaskReward: number;
  close:() => void
};

const VideoTaskReward = ({ answer, TaskReward,close }: Props) => {
  const { user, t } = useContext(AppContext);
  const text = t('tasksPage')
  const { RewardAnimationContextValue } = useRewardAnimation({
    balanceValue: user?.rep || 0,
    rewardValue: TaskReward,
    onEndAnimation: close,
  });

  const {
    balance,
    reward,
    endTargetRefAnimation,
    startAnimation,
    startTargetRefAnimation,
    animationItems,
    animateBalanceBox,
    setAnimateBalanceBox,
  } = RewardAnimationContextValue;

  useEffect(() => {
    setTimeout(startAnimation,1000)
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{duration:0.7}}
      className="video-tark-reward-screen"
    >
      <h2>{answer ? "Ya" : "No"}</h2>
      <motion.div
        initial={{ y: 0 }}
        animate={{ scale: animateBalanceBox ? 1.1 : 1 }}
        transition={{ duration: 0.05 }}
        onAnimationComplete={() => setAnimateBalanceBox(false)}
        className="money"
      >
        <span
          ref={endTargetRefAnimation}
          className="big-green"
          style={{ fontSize: 25 }}
        >
         <img src="/img/rep.png" className="size-24" alt=""/>
        </span>
        <span id="money-value">+ {formatNum(Math.floor(balance))}</span>
      </motion.div>

      <div className="money-plus">
        <span
          ref={startTargetRefAnimation}
          className="big-green"
          style={{ fontSize: 25 }}
        >
         <img src="/img/rep.png" className="size-24" alt=""/>
        </span>
        <span id="money-plus-value">
          + {formatNum(Math.floor(reward))}{" "}
          <span style={{ fontFamily: "InterRegular" }}></span>
        </span>
      </div>
      {animationItems}
    </motion.div>
  );
};

export default VideoTaskReward;
