import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./VideoTask.css";
import PageWrapper from "UI/PageWrapper";
import VideoTaskQuestion from "./components/VideoTaskQuestion";
import VideoTaskReward from "./components/VideoTaskReward";
import { useLocation, useNavigate } from "react-router-dom";
import Task from "types/task";
import doneVideoTask from "api/done-video-task";
import RewardScreen from "UI/RewardScreen";
import AppContext from "utils/app-context";
import Tag from "types/tag";
import VideoTaskTop from "./components/VideoTaskTop";
import VideoTaskStatus from "./components/VideoTaskStatus";
import claimVideoTask from "api/claim-video-task";
import tg from "utils/tg";

type Props = {
  task: Task;
  tags: Tag[] | null;
  settags: Dispatch<SetStateAction<Tag[] | null>>;
  next: () => void;
  paginate: (newDirection: number, closeIfVoted?: true) => void;
};

const VideoTask = ({ task, settags, tags, next, paginate }: Props) => {
  const [stage, setstage] = useState(0);
  const [loading, setloading] = useState(false);
  const [answer, setanswer] = useState<null | boolean>(null);
  const [result, setresult] = useState(false);
  const [finalReward, setfinalReward] = useState<null | number>(null);
  const { user, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [bid, setBid] = useState<number>(task?.bid_min || 0);
  useEffect(() => {
    if (answer === null || !task) return;
    doneVideoTask(task.id, answer, bid).then((res) => {
      if (res) {
        setresult(true);
      }
    });
  }, [answer]);
  useEffect(() => {}, []);

  useEffect(() => {
    document.querySelector("button")?.focus();
    const onBlur = () => {
      const iframe = document.querySelector("iframe");
      if (
        document.activeElement &&
        document.activeElement.nodeName.toLowerCase() === "iframe" &&
        iframe &&
        iframe === document.activeElement
      ) {
        if (!task) return;
        setstage(1);
        const seconds = Number(task?.duration) * 1000;
        setTimeout(() => setstage(2), seconds);
        window.removeEventListener("blur", onBlur);
      }
    };

    if (task.status !== 0) setstage(2);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return (
    <>
      {/* {
          task?.status === 0
          ? <VideoTaskTop task={task} stage={stage} settags={settags} tags={tags}/>
          : task && <VideoTaskStatus task={task} setfinalReward={setfinalReward}/>
      } */}
      <VideoTaskTop
        task={task}
        stage={stage}
        settags={settags}
        tags={tags}
        setfinalReward={setfinalReward}
      />
      <div
        className="iframe-container"
        dangerouslySetInnerHTML={{ __html: task?.iframe_video || "" }}
      />
      {stage !== 1 && (
        <>
          <button
            onClick={() => paginate(-1)}
            className="video-task-button prev"
          />
          <button
            onClick={() => paginate(1)}
            className="video-task-button next"
          />
        </>
      )}
      {task && (
        <VideoTaskQuestion
          task={task}
          disabled={task?.status !== 0}
          stage={stage}
          handleQuestion={setanswer}
          loading={loading}
          setloading={setloading}
          answer={answer}
          bid={bid}
          setBid={setBid}
        />
      )}
      {result && answer !== null && (
        <VideoTaskReward
          answer={answer}
          TaskReward={Number(task?.reward)}
          close={() => {
            setUser((prev) => {
              if (prev) {
                return { ...prev, rep: prev.rep + Number(task?.reward) };
              }
              return prev;
            });
            setTimeout(next, 500);
          }}
        />
      )}
      {finalReward && (
        <div
          style={{ position: "absolute", inset: 0 }}
          className="final-reward-screen"
        >
          <RewardScreen
            rewardCount={finalReward}
            rewardData={{
              reward_type: "rep",
              status: 1,
              balance: user?.balance,
            }}
            beforeStartAnimation={async () => {
              const result = await claimVideoTask(task.id);
              if (result?.error) {
                tg.showAlert(result.error,() => {
                  setfinalReward(null);
                });
                return false;
              }
              return true;
            }}
            close={() => {
              navigate("/main/tasks?type=completed");
            }}
          />
        </div>
      )}
    </>
  );
};

export default VideoTask;
