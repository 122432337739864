import React, { useEffect, useState } from "react";
import Icon from "UI/Icon";

type Props = {
  seconds?: number;
  onEnd?: () => void;
};

const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const s = (seconds % 60).toString().padStart(2, "0");
  return `${h}:${m}:${s}`;
};

const RatingTimer = ({ seconds = 0, onEnd }: Props) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (timeLeft <= 0) {
      onEnd?.();
      return;
    }
    const interval = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft, onEnd]);

  return (
    <div className="rating-time">
      <Icon icon="timer" />
      <p>{formatTime(timeLeft)}</p>
    </div>
  );
};

export default RatingTimer;
