import { motion } from "framer-motion";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import Tag from "types/tag";
import AppContext from "utils/app-context";
import VideoTaskShare from "./VideoTaskShare";
import Task from "types/task";
import claimVideoTask from "api/claim-video-task";
import tg from "utils/tg";
import Icon from "UI/Icon";

type Props = {
  task: Task;
  stage: number;
  tags:Tag[] | null;
  settags: Dispatch<SetStateAction<Tag[] | null>>;
  setfinalReward:(r:number) => void 
};

const STORAGE_KEY = "selectedTags";

const VideoTaskTop = ({ task, stage ,settags,tags,setfinalReward}: Props) => {
  const {t} = useContext(AppContext)
  const [shareMenu, setShareMenu] = useState(false);
  const [opened, setOpened] = useState(false);
      const [loading, setloading] = useState(false)
  
  const text = t('videoTasks')

  const handleClaim = async () => {
    if(task.is_claimed || Number(task.seconds) > 0) return
    // setloading(true)
    setfinalReward(task.vote_reward)
    // const result = await claimVideoTask(task.id)
    // if(result?.error) {
    //   tg.showAlert(result.error)
    // } else setfinalReward(task.vote_reward)
    // setloading(false)
  }
  const isNotClaimed = (!!task.vote_reward && !task.is_claimed)
  return (
    <motion.div
      className="video-task-top"
      animate={stage !== 1 ? { height: "auto" } : { height: 0 }}
    >
      <motion.div
        animate={opened ? { height: "auto" } : { height: 0 }}
        transition={{ duration: 0.2 }}
        className="video-task-top--tags"
      >
        <ul className="video-task-top--tags-list">
          {tags &&
            tags.map(({ id, title, active }) => (
              <button
                onClick={() => {
                  settags(
                    tags.map((t) =>
                      t.id === id ? { ...t, active: !active } : t
                    )
                  );
                }}
                key={id}
                data-active={active}
              >
                {title}
              </button>
            ))}
        </ul>
      </motion.div>
      <div className="video-task-top--share">
        {!isNotClaimed ? <button
          onClick={() => setShareMenu(!shareMenu)}
          className="share"
        >
          {text.share}
        </button> : 
        <button onClick={handleClaim} className="share claim">
          <img src="\img\coin3.png" alt=""/>
          <p>CLAIM</p>
          {loading && <Icon icon="loading"/>}
        </button>
        }
        <button
          className="open-filter"
          data-opened={opened}
          onClick={() => setOpened(!opened)}
        />
      </div>
      <VideoTaskShare
        shareMenu={shareMenu}
        setShareMenu={setShareMenu}
        task={task}
      />
    </motion.div>
  );
};

export default VideoTaskTop;
