import React, { useEffect, useState } from "react";

type Props = {
  initialSeconds?: number | null; // начальное значение секунд
  onEnd?:() => void
};

const UpdateTimer = ({ initialSeconds = 0,onEnd }: Props) => {
  const [seconds, setSeconds] = useState<number | null>(initialSeconds);

  useEffect(() => {
    if (!seconds) return;

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if(seconds === 0) onEnd?.()
        return (prevSeconds ? prevSeconds - 1 : 0)
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  if (!seconds) return null;

  return (
    <div className="update-timer">
      <img src="/img/clock.svg" alt="" />
      <p>{formatTime(seconds)}</p>
    </div>
  );
};

function formatTime(seconds: number): string {
  if (seconds > 172800) {
    // Больше 2 суток
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    return `${days} d. ${hours} h.`;
  }

  // Меньше 2 суток - формат hh:mm:ss
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(secs)}`;
}

const formatNumber = (num: number) => String(num).padStart(2, "0");

export default UpdateTimer;
